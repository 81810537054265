import { combineReducers } from 'redux'
import experiences from 'mgr/pages/single-venue/marketing/reducers/Experiences'
import currentEvent from './currentEventReducer'
import eventListData from './eventListReducer'
import inventoryId from './inventoryIdReducer'
import isLoading from './isLoading'
import seatingAreas from './seatingAreasReducer'
import upsells from './upsellsReducer'
import ux from './uxReducer'
import venueConfig from './venueConfigReducer'
import venueId from './venueIdReducer'

const eventManager = combineReducers({
  currentEvent,
  venueId,
  inventoryId,
  eventListData,
  ux,
  isLoading,
  seatingAreas,
  upsells,
  experiences,
  venueConfig,
})

export default eventManager
