import _ from 'lodash'
import { fromJS } from 'immutable'
import { POLICY_TYPE } from 'mgr/events/utils/PolicyType'
import {
  SAVE_EVENT_SUCCESS,
  LOAD_EVENTS,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENT_SALES_SUCCESS,
  LOAD_EVENT_INVENTORIES_SUCCESS,
  ADD_EVENT,
  UPDATE_EVENT,
  LOAD_EVENT_IMAGE_DATA,
  LOAD_EVENT_DESCRIPTION_DATA,
  DELETE_EVENT,
  DELETE_EVENT_SUCCESS,
  CLONE_EVENT,
  UPDATE_DAYS,
  TOGGLE_RECURS,
  ADD_INVENTORY,
  SAVE_INVENTORY,
  SAVE_INVENTORY_SUCCESS,
  EXCLUDE_DATE_EVENT_SUCCESS,
  DELETE_INVENTORY_SUCCESS,
  UPDATE_INVENTORY,
  DELETE_INVENTORY,
  CLONE_INVENTORY,
  LOAD_INVENTORY,
  UPLOAD_PHOTO_PREVIEW,
  UPLOAD_PHOTO_SUCCESS,
  DELETE_PHOTO,
  UPDATE_PHOTO_TITLE,
  CROP_PHOTO,
  CHANGE_BOOKING_POLICY_TYPE,
  CHANGE_BOOKING_POLICY,
  CHANGE_CANCELLATION_POLICY_TYPE,
  CHANGE_CANCELLATION_POLICY,
  CHANGE_INVENTORY_BOOKING_POLICY_TYPE,
  CHANGE_INVENTORY_BOOKING_POLICY,
  CHANGE_INVENTORY_CANCELLATION_POLICY_TYPE,
  CHANGE_INVENTORY_CANCELLATION_POLICY,
  CHANGE_SELECTED_TAGS,
  RESET_EVENT,
} from '../actions/ActionTypes'
import eventStructure from '../assets/structures/event'
import inventoryStructure from '../assets/structures/inventory'

const initialPhotoMapState = {
  1: { label: '', url: null, cropData: null },
  2: { label: '', url: null, cropData: null },
  3: { label: '', url: null, cropData: null },
  4: { label: '', url: null, cropData: null },
  5: { label: '', url: null, cropData: null },
  6: { label: '', url: null, cropData: null },
  7: { label: '', url: null, cropData: null },
}

const photos = (state = {}, action) => {
  const change = {}

  switch (action.type) {
    case UPLOAD_PHOTO_PREVIEW:
      return state.update(String(action.photoId), obj => obj.set('url', action.url))

    case UPLOAD_PHOTO_SUCCESS:
      return state.update(String(action.photoId), obj =>
        obj.merge({
          url: `/.h/download/${action.actions.url_key}`,
          blob_key: action.actions.post_key,
        })
      )

    case DELETE_PHOTO:
      change[action.photoId] = { url: null, cropData: null, label: '' }
      return state.merge(change)

    case CROP_PHOTO:
      return state.update(String(action.photoId), obj => obj.set('cropData', action.coords))

    case UPDATE_PHOTO_TITLE:
      return state.update(String(action.photoId), obj => obj.set('label', action.value))

    default:
      return state
  }
}

const getSelectedEventInventoryPolicy = (customPolicy, policyId) => {
  if (customPolicy) {
    return POLICY_TYPE.CUSTOM
  }

  if (policyId) {
    return policyId
  }

  // inventory without a custom policy or policyId set will default to event policy.
  return POLICY_TYPE.EVENT
}

const inventory = (state = {}, currentEvent, action) => {
  switch (action.type) {
    case CHANGE_INVENTORY_BOOKING_POLICY_TYPE:
      if (state.get('id') !== action.inventoryId) {
        return state
      }
      return state.set('selected_booking_policy', action.value)

    case CHANGE_INVENTORY_BOOKING_POLICY:
      if (state.get('id') !== action.inventoryId) {
        return state
      }
      return state.set('custom_booking_policy', action.value)

    case CHANGE_INVENTORY_CANCELLATION_POLICY_TYPE:
      if (state.get('id') !== action.inventoryId) {
        return state
      }
      return state.set('selected_cancellation_policy', action.value)

    case CHANGE_INVENTORY_CANCELLATION_POLICY:
      if (state.get('id') !== action.inventoryId) {
        return state
      }
      return state.set('custom_cancellation_policy', action.value)

    case CHANGE_SELECTED_TAGS:
      return state.set('selected_tags', action.value)

    case LOAD_INVENTORY:
      if (state.get('id') !== action.id) {
        return state
      }

      const booking_policy_id = state.get('booking_policy_id')
      const custom_booking_policy = state.get('custom_booking_policy')
      const selected_booking_policy = getSelectedEventInventoryPolicy(custom_booking_policy, booking_policy_id)

      const cancellation_policy_id = state.get('cancellation_policy_id')
      const custom_cancellation_policy = state.get('custom_cancellation_policy')
      const selected_cancellation_policy = getSelectedEventInventoryPolicy(custom_cancellation_policy, cancellation_policy_id)

      return state.set('selected_booking_policy', selected_booking_policy).set('selected_cancellation_policy', selected_cancellation_policy)

    case UPDATE_INVENTORY:
      if (state.get('id') !== action.inventoryId) {
        return state
      }
      if (action.field === 'inventory_tier') {
        let value = state.get('tier_list')
        if (action.value) {
          value = value.push(action.toggledInventory)
        } else {
          const index = value.indexOf(action.toggledInventory)
          value = value.splice(index, 1)
        }

        if (value.size === 0) {
          return state.merge({
            hide_tiering: true,
            tier_list: value,
          })
        }
        return state.set('tier_list', value)
      } else if (action.field === 'hide_tiering') {
        let inventory_ids = []
        if (!action.value) {
          inventory_ids = action.inventories.map(inventory => inventory.id)
        }
        return state.merge({
          hide_tiering: action.value,
          tier_list: inventory_ids,
        })
      }
      if (action.field === 'service_charge_type') {
        if (action.value === 'DEFAULT_SERVICE_CHARGE') {
          state = state.set('service_charge_amount', document.getElementById('default_service_charge').value)
        }
      }
      if (action.field === 'gratuity_amount_type') {
        if (action.value === 'CUSTOMER_VARIABLE') {
          state = state.set('gratuity_amount', '0')
        } else if (action.value === 'DEFAULT_FIXED') {
          state = state.set('gratuity_amount', document.getElementById('default_gratuity').value)
        }
      }
      if (action.field === 'charge_gratuity' && action.value) {
        state = state.set('gratuity_amount_type', 'DEFAULT_FIXED')
        state = state.set('gratuity_amount', document.getElementById('default_gratuity').value)
      }
      if (action.field === 'inventory_type' && action.value === 'REQUEST') {
        state = state
          .set('selected_booking_policy', POLICY_TYPE.EVENT)
          .set('custom_booking_policy', '')
          .set('selected_cancellation_policy', POLICY_TYPE.EVENT)
          .set('custom_cancellation_policy', '')
      }
      return state.set(action.field, action.value)
    default:
      return state
  }
}

const eventData = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_BOOKING_POLICY_TYPE:
      if (state.get('id') !== action.id) {
        return state
      }
      return state.set('selected_booking_policy', action.value)

    case CHANGE_BOOKING_POLICY:
      if (state.get('id') !== action.id) {
        return state
      }
      return state.set('custom_booking_policy', action.value)

    case CHANGE_CANCELLATION_POLICY_TYPE:
      if (state.get('id') !== action.id) {
        return state
      }

      return state.set('selected_cancellation_policy', action.value)

    case CHANGE_CANCELLATION_POLICY:
      if (state.get('id') !== action.id) {
        return state
      }
      return state.set('custom_cancellation_policy', action.value)

    case LOAD_EVENT_IMAGE_DATA:
    case LOAD_EVENT_DESCRIPTION_DATA:
    case UPDATE_EVENT:
      if (state.get('id') !== action.id) {
        return state
      }
      if (action.field === 'private_event') {
        return state.update(action.field, flag => !flag)
      }
      return state.set(action.field, action.value)

    case TOGGLE_RECURS:
      if (state.get('id') !== action.id) {
        return state
      }
      return state.set('show_recurs', !state.get('show_recurs'))

    case UPDATE_DAYS:
      if (state.get('id') !== action.id) {
        return state
      }
      const days = state.get('day_of_week')
      if (!action.on) {
        return state.set(
          'day_of_week',
          days.filter(day => day !== action.day)
        )
      }
      return state.set('day_of_week', days.push(action.day))

    case UPLOAD_PHOTO_SUCCESS:
    case UPLOAD_PHOTO_PREVIEW:
    case DELETE_PHOTO:
    case CROP_PHOTO:
    case UPDATE_PHOTO_TITLE:
      if (state.get('id') !== action.id) {
        return state
      }
      return state.set('photo_map', photos(state.get('photo_map'), action))

    case CHANGE_INVENTORY_BOOKING_POLICY:
    case CHANGE_INVENTORY_BOOKING_POLICY_TYPE:
    case CHANGE_INVENTORY_CANCELLATION_POLICY_TYPE:
    case CHANGE_INVENTORY_CANCELLATION_POLICY:
    case LOAD_INVENTORY:
      return state.set(
        'inventories',
        state.get('inventories').map(i => inventory(i, state.toJS(), action))
      )
    case CHANGE_SELECTED_TAGS:
    case UPDATE_INVENTORY:
      if (state.get('id') !== action.id) {
        return state
      }
      return state.set(
        'inventories',
        state.get('inventories').map(i => inventory(i, state.toJS(), action))
      )
    case DELETE_INVENTORY:
      if (state.get('id') !== action.id) {
        return state
      }
      return state.set(
        'inventories',
        state.get('inventories').filter(item => item.get('id') !== action.inventoryId)
      )
    case CLONE_INVENTORY:
      if (state.get('id') !== action.id) {
        return state
      }
      const inv = state
        .get('inventories')
        .filter(item => item.get('id') === action.inventoryId)
        .get(0)
      const newInv = inv.set('inventory_name', `Copy of ${inv.get('inventory_name')}`).set('id', 'new')

      return state.set('inventories', state.get('inventories').push(newInv))
    case ADD_INVENTORY:
      if (state.get('id') !== action.id) {
        return state
      }
      return state.set('inventories', state.get('inventories').push(fromJS({ ...inventoryStructure, event_id: action.id })))
    default:
      return state
  }
}

const getSelectedEventPolicy = (customPolicy, policyId, policies) => {
  if (customPolicy) {
    return POLICY_TYPE.CUSTOM
  }

  return policyId || policies[0].id
}

const eventListData = (state = fromJS([]), action) => {
  const tempStateObj = state.toJS()
  const event = _.find(tempStateObj, { id: action.id }) || _.find(tempStateObj, { id: 'new' })
  const inventory = event ? _.find(event.inventories, { id: action.inventoryId }) || _.find(event.inventories, { id: 'new' }) : null
  switch (action.type) {
    case LOAD_EVENTS:
      return state
    case LOAD_EVENTS_SUCCESS:
      const events = _.map(action.events, event => {
        event.selected_booking_policy = getSelectedEventPolicy(
          event.custom_booking_policy,
          event.booking_policy_id,
          action.booking_policies
        )
        event.selected_cancellation_policy = getSelectedEventPolicy(
          event.custom_cancellation_policy,
          event.cancellation_policy_id,
          action.cancellation_policies
        )
        return { ...event, photo_map: _.isEmpty(event.photo_map) ? initialPhotoMapState : event.photo_map }
      })
      return fromJS(events)
    case LOAD_EVENT_SALES_SUCCESS: {
      const { eventSales } = action
      _.map(tempStateObj, event => {
        event.sales_data = eventSales[event.id] || event.sales_data
      })
      return fromJS(tempStateObj)
    }
    case LOAD_EVENT_INVENTORIES_SUCCESS: {
      const { eventInventories } = action
      _.map(tempStateObj, event => {
        event.inventories = eventInventories[event.id] || event.inventories
      })
      return fromJS(tempStateObj)
    }
    case RESET_EVENT:
      const copyList = [...tempStateObj]
      const eventIndex = copyList.findIndex(el => el.id === (action.event ? action.event.id : 'new'))

      if (action.event) {
        copyList[eventIndex] = action.event
      } else {
        copyList.splice(eventIndex, 1)
      }

      return fromJS(copyList)

    case DELETE_EVENT_SUCCESS:
      const fevents = _.filter(tempStateObj, event => event.id !== action.id)
      return fromJS(fevents)
    case SAVE_INVENTORY:
      return fromJS(tempStateObj)
    case SAVE_EVENT_SUCCESS:
      if (event) {
        event.id = action.id
      } else {
        tempStateObj.push(action.event)
      }
      return fromJS(tempStateObj)

    case CLONE_EVENT:
      const eve = state.filter(item => item.get('id') === action.id).get(0)
      const newEve = eve.set('name', `Copy of ${eve.get('name')}`).set('id', 'new')

      return state.push(newEve)

    case EXCLUDE_DATE_EVENT_SUCCESS:
      if (action.selectedValue) {
        event.excluded_dates = _.union(event.excluded_dates, [action.dt])
      } else {
        event.excluded_dates = _.difference(event.excluded_dates, [action.dt])
      }
      event.sales_data.sales = event.sales_data.sales.map(sale =>
        sale.event_date === action.dt ? { ...sale, hidden: action.selectedValue } : sale
      )
      return fromJS(tempStateObj)
    case SAVE_INVENTORY_SUCCESS:
      inventory.id = action.inventoryId
      return fromJS(tempStateObj)
    case ADD_EVENT:
      return state.push(fromJS({ ...eventStructure, venue_id: action.venueId }))
    case DELETE_EVENT:
      return fromJS(tempStateObj.filter(item => item.get('id') !== action.id))
    case ADD_INVENTORY:
      event.inventories.push(fromJS({ ...inventoryStructure, event_id: event.id }))
      return fromJS(tempStateObj)
    case DELETE_INVENTORY:
      event.inventories = event.inventories.filter(inventory => inventory.id !== action.value)
      return fromJS(tempStateObj)
    case DELETE_INVENTORY_SUCCESS:
      event.inventories = event.inventories.filter(inventory => inventory.id !== action.inventoryId)
      for (const inventory in event.inventories) {
        const inventoryTierList = event.inventories[inventory].tier_list
        if (inventoryTierList.indexOf(action.inventoryId) === -1) {
          continue
        }
        event.inventories[inventory].tier_list = inventoryTierList.filter(inventoryId => inventoryId !== action.inventoryId)
        if (_.size(event.inventories[inventory].tier_list) === 0) {
          event.inventories[inventory].hide_tiering = true
        }
      }
      return fromJS(tempStateObj)

    case CHANGE_INVENTORY_BOOKING_POLICY_TYPE:
    case CHANGE_BOOKING_POLICY_TYPE:
    case CHANGE_BOOKING_POLICY:
    case CHANGE_CANCELLATION_POLICY_TYPE:
    case CHANGE_CANCELLATION_POLICY:
    case UPLOAD_PHOTO_SUCCESS:
    case UPLOAD_PHOTO_PREVIEW:
    case UPDATE_PHOTO_TITLE:
    case LOAD_EVENT_IMAGE_DATA:
    case LOAD_EVENT_DESCRIPTION_DATA:
    case UPDATE_EVENT:
    case UPDATE_DAYS:
    case DELETE_PHOTO:
    case CROP_PHOTO:
    case TOGGLE_RECURS:
    case UPDATE_INVENTORY:
    case CLONE_INVENTORY:
    case CHANGE_INVENTORY_BOOKING_POLICY:
    case CHANGE_INVENTORY_CANCELLATION_POLICY_TYPE:
    case CHANGE_INVENTORY_CANCELLATION_POLICY:
    case LOAD_INVENTORY:
    case CHANGE_SELECTED_TAGS:
      return state.map(e => eventData(e, action))
    default:
      return state
  }
}

export default eventListData
