export const photoWrapper = {
  clear: 'both',
  paddingTop: 20,
}

export const emptyPhoto = {
  background: `#f5f5f5 url(${MEDIA_URL}images/add_picture.png) 50% 50% no-repeat`,
  border: '1px dashed #ccc',
  cursor: 'pointer',
  float: 'left',
  position: 'relative',
}

export const hasPhoto = {
  border: '1px solid #fff',
  float: 'left',
  position: 'relative',
  overflow: 'hidden',
}

export const bigPhoto = {
  backgroundSize: '42px 32px',
  height: 192,
  marginRight: 20,
  marginTop: 10,
  width: 400,
}

export const smallPhoto = {
  backgroundSize: '28px 21.5px',
  height: 85,
  margin: '0 20px 20px 0',
  width: 85,
}

export const smallPhotoSet = {
  marginTop: 10,
  float: 'left',
  width: 330,
}

export const actionAdjust = {
  height: 25,
  width: 21,
}

export const photoActions = {
  right: 4,
  position: 'absolute',
  top: 4,
}

export const photoLabel = {
  color: '#aaa',
  bottom: -15,
  fontSize: 10,
  fontStyle: 'italic',
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 85,
}

export const photoStyle = {
  left: '-25%',
  position: 'absolute',
  width: '150%',
}

export const cropWrap = {
  background: '#fff',
  left: '50%',
  marginLeft: -200,
  position: 'fixed',
  top: '5%',
  width: 400,
  zIndex: 1001,
}
